import React, { useEffect } from "react";
import Layout from "../_components/Layout/Layout";
import UserForm from "../_components/UserForm/UserForm";
import MainTitle from "../_components/MainTitle/MainTitle";
import PageSummary from "../_components/PageSummary/PageSummary";
import { goto } from "../_utils/Misc";
import Qr from "../_utils/Qr";

const Registration = () => {
    useEffect(() => {
        if (!Qr.hasStoredParams()) {
            goto("/error");
        }
    }, []);

    return (
        <Layout title="Registration" hideSubNav={true}>
            <MainTitle className="registration">Register your details</MainTitle>

            <PageSummary>
                <p className="form-subheader">
                    Sharing your details helps us deliver the best experience to you.
                    You also have the opportunity to join our community of fun events and festivities.
                    Be sure to use a valid email to receive your gift!
                </p>
            </PageSummary>

            <UserForm showEmail={true} btnText="Unlock my gift" isAccountForm={false}/>
        </Layout>
    );
};

export default Registration;
